// This file should only be included once, in index.scss

// Dealing with justifying content in the middle and setting a max width
.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    .content {
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
}
