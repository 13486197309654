@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

.test {
    width: 300px;
    height: 100px;
    display: block;
    background-color: red;
}

.Songs {
    display: flex;
    flex-direction: column;
    color: colors.$songs-text-color;
    font-size: 16px;

    .playlist-title {
        color: colors.$songs-playlist-title;
        font-weight: 700;
        font-size: 36px;
    }

    .search {
        @include media-breakpoint-down(md) {
            flex-direction: row;
        }

        .search-dates {
            @include media-breakpoint-down(md) {
                .right-dropdown {
                    .content {
                        right: 0;
                    }
                }
            }

            .between-above {
                &:before {
                    content: 'BETWEEN:';
                    font-weight: 600;
                    position: absolute;
                    top: calc(-100% + 5px);
                    left: 12px;
                }
            }
        }
    }

    .log {

        table {
            border-collapse: separate;
            width: 100%;
            border-spacing: 0 10px;

            @include media-breakpoint-up(md) {
                border-spacing: 0 20px;
            }

            th {
                font-weight: 600;
                font-size: 16px;
                text-align: left;
            }

            tbody {
                td {
                    font-weight: 400;
                }
            }

            .time {
                text-align: end;
            }

            .link {
                text-align: end;
            }

            .ArtworkDisplay {
                .image {
                    width: 50px;
                    height: 50px;
                }

                @include media-breakpoint-up(md) {
                    .image {
                        width: 75px;
                        height: 75px;
                    }

                    .text-container {
                        gap: 12px;
                    }
                }
            }

            .time-small {
                text-align: end;

                span {
                    display: block;
                }
            }
        }

        .more {
            margin-top: 50px;
            display: flex;
            justify-content: center;
        }
    }
}
