@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.OnAir {
    .logger-box {
        margin-top: 30px;
        font-weight: 600;

        & > span {
            display: block;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }
}
