@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

// TODO: Legacy; Replace with bootstrap stuff
$blue-space: 27px * calc(2/3);

.ListingComponent {
    //display: flex;
    //justify-content: space-evenly;
    width: 100%;
    //
    //flex-direction: row;
    //flex-wrap: wrap;

    & > .title {
        color: colors.$home-title;
        font-weight: 700;
        font-size: 36px;
        margin: 50px 0 23px 0;
    }

    .items {
        //display: flex;
        //justify-content: space-between;
        display: grid;
        //grid-template-columns: repeat(auto-fit, ${itemWidth}px); // set in js
        grid-row-gap: $blue-space;
        //justify-content: space-between;

        width: 100%;

        flex-direction: row;
        flex-wrap: wrap;

        .item {
            display: flex;
            flex-direction: column;

            .image {

            }

            .title {
                font-weight: 600;
                font-size: 20px;
                margin-top: 0.5em;
            }

            .subtitle {
                font-weight: 400;
                font-size: 16px;
            }
        }
    }
}

//.SingleAlbumReview {
//    display: inline-block;
//    border: 1px solid $background-color;
//    border-radius: 3px;
//    margin: 0 20px;
//    padding: 25px;
//    max-width: 250px;
//
//    .image {
//        aspect-ratio: 1;
//        margin: 0 auto;
//        display: block;
//    }
//
//    h3 {
//
//    }
//
//    p {
//
//    }
//}