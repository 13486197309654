@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

$itemWidth: 500px;
$itemWidthSmall: 350px;

.Schedule {
    display: flex;
    flex-direction: column;

    .primary-title {
        color: colors.$schedule-title;
        font-weight: 700;
        font-size: 24px;
        margin: 50px 0 25px 0;
    }

    .secondary-title {
        color: colors.$text-color-primary;
        font-weight: 600;
        font-size: 20px;
        margin: 0 0 15px 0;
    }

    .on-air-title {
        color: colors.$schedule-title;
        font-weight: 600;
        font-size: 20px;
        margin: 0 0 7px 0;
    }

    .today {
        .on-air {
            position: relative;

            @include media-breakpoint-up(lg) {
                margin-right: 8px;
            }
        }

        .divider {
            position: relative;

            &::after {
                content: "";
                display: block;
                border-right: 1px solid colors.$schedule-separator;
                height: 180px;
                bottom: 10px;
                position: absolute;
            }
        }

        .coming-next {
            width: 100%;

            .secondary-title {
                display: none;
            }
        }

        .on-air + .coming-next {
            @include media-breakpoint-up(lg) {
                width: calc(100% - $itemWidth);
            }

            .secondary-title {
                display: block;
            }
        }
    }

    .this-week:not(.primary-title + .this-week) {
        margin-top: 50px;
    }

    .item {
        display: flex;
        flex-direction: row;
        user-select: none;

        width: $itemWidth;

        .image {
            width: 200px;
            height: 200px;
            user-select: none;
        }

        @include media-breakpoint-down(sm) {
            width: $itemWidthSmall;

            .image {
                width: 150px;
                height: 150px;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            margin-left: 15px;

            max-width: 300px;

            color: colors.$sidebar-text-color;

            .title {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 14px;
            }

            .time {
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 8px;
            }

            .description {
                font-weight: 400;
                font-size: 14px;
                margin: 0;
            }
        }
    }
}