@use "src/scss/colors" as colors;
@use "src/scss/mixins" as mixins;

.Lister {
    .items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
        grid-gap: 50px 20px;
        margin: 1em 0 3em;
        justify-items: center;
    }

    .drag-placeholder {
        display: none;
    }
    
    .placeholder-show {
        display: block;
    }
    
    .drag-wrapper {
        &.interm {
            position: unset;
        }
        
        position: relative;

        &.left-highlight::before, &.right-highlight::after {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            background: var(--bs-blue);
            height: calc(100% - 20px);
            width: 2px;
        }
        
        &.left-highlight::before {
            left: -10px;
        }

        &.right-highlight::after {
            right: -10px;
        }
    }

    .dragging-item {
        position: absolute;
        
        &.high-z {
            z-index: 99999;
        }
    }
}
