@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.Upload {
    flex-direction: column;

    .submit {
        border-radius: 4px;
        width: fit-content;
        padding: 8px;
        border: none;
        cursor: pointer;
        color: #FFF;
        text-decoration: none;
        background: #00E000;
    }
}
