@use "src/scss/mixins" as *;

.UserCard {
    .card-body {
        z-index: 100;
    }

    .popup-container {
        z-index: 1000;

        .more-dots {
            cursor: pointer;
        }
    }

    .popup {
        z-index: $zindex-dropdown;
    }
}