@use "src/scss/colors";
@use "src/scss/mixins" as *;

.Sidebar {
    box-sizing: border-box;
    display: block;
    flex: 0 1 100%;
    //width: 100%;
    max-width: 100%;
    margin: 0;

    @include media-breakpoint-down(md) {
        flex: 0 1 25%;
        max-width: 400px; // TODO: Goes against nihal's design
        margin: 0 50px 0 0;
    }

    @if (colors.$use-debug-colors) {
        background: #673ab7;
    }

    .sidebar-component {
        &:not(:first-child) {
            margin-top: 50px;
        }
    }
}

.sidebar-component {
    color: colors.$sidebar-text-color;

    .title {
        color: colors.$sidebar-title-color;
        font-weight: 700;
        font-size: 24px;
        //line-height: 49px;
        //margin: 0 0 20px;
    }
}
