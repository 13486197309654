@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.Contact {
    display: flex;
    flex-direction: column;

    .primary-title {
        color: colors.$schedule-title;
        font-weight: 700;
        font-size: 24px;
    }

    .secondary-title {
        color: colors.$text-color-primary;
        font-weight: 600;
        font-size: 20px;
    }

    .people {
        .contact {
            .image {
                border-radius: 50%;
                width: 100px;
                height: 100px;

                &.has-secondary:hover {
                    content: var(--secondary-image);
                }
            }

            .text-container {
                .person {
                    font-weight: 600;
                    font-size: 20px;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }

    .social {
        a {
            text-decoration: none;
            color: #000;
            display: block;

            &:hover {
                color: colors.$accent;
            }
        }
    }
}