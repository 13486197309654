@use "src/scss/mixins" as *;
@import '~croppie/croppie.css';

.UploadImageModal {
    // TODO: this is bad
    z-index: 99999 !important;
}

.croppie-wrapper {
    margin: 0 auto;
}

.image-preview {
    aspect-ratio: 1;
    width: 100%;
    display: block;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: $btn-border-radius;

    &:hover .edit-image {
        transition: opacity 100ms ease-out;
        opacity: 0.7;
    }

    .edit-image {
        position: absolute;
        transition: opacity 100ms ease-in;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: lighten(black, 30%);
        border-radius: $btn-border-radius;
        cursor: pointer;
        opacity: 0;
        color: white;
    }

    .image-upload {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
}
