.SideScroll {
    position: relative;
    display: flex;
    align-items: center;

    .arrow {
        position: absolute;
        cursor: pointer;
        user-select: none;
        pointer-events: none;

        text-align: end;
        overflow: hidden;
        width: 100%;
        left: 0;
    }

    .left-arrow {
        text-align: start;

        .material-symbols-outlined {
            transform: rotate(180deg);
        }
    }

    .right-arrow {
        text-align: end;
    }

    .material-symbols-outlined {
        font-variation-settings:
                'FILL' 0,
                'wght' 100,
                'GRAD' 0,
                'opsz' 48;

        font-size: 5em;
        width: 50px;
        cursor: pointer;
        pointer-events: all;
        user-select: none;

        &:active {
            opacity: 0.8;
        }
    }

    .items {
        overflow: scroll;
        scroll-snap-type: x mandatory;
        gap: 8px;

        //padding-right: 100px;

        &::-webkit-scrollbar {
            display: none;
        }

        & > * {
            scroll-snap-align: start;
        }

        &.overflow-left::before, &.overflow-right::after {
            content:'';
            width: 50px; // was 75px
            height: 100%;
            position: absolute;
            top: 0;
        }

        &.overflow-right::after {
            right: -1px;
            background: linear-gradient(270deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.9) 35%, rgba(0,0,0,0) 100%);
        }

        &.overflow-left::before {
            left: -1px;
            background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.9) 35%, rgba(0,0,0,0) 100%);
        }
    }
}