@use "src/scss/mixins" as *;

.EditShow {
    
    .image-upload-wrapper {
        @include media-breakpoint-up(md) {
            right: 0;
            position: absolute;
        }
    }
    
    .UploadImage {
        width: 300px;
    }
}