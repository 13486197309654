@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

.About {
    p {
        font-weight: 400;
        font-size: 16px;
        margin: 0;
    }

    h3 {
        color: colors.$about-title;
        font-weight: 700;
        font-size: 24px;
        margin: 0 0 24px 0;
    }

    .div-image {
        position: relative;
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: var(--image);

        @include media-breakpoint-down(md) {
            aspect-ratio: calc(4/6);
        }

        &::after {
            content: 'Image by Liam Donnell';
            position: absolute;
            margin-top: 0.5em;
            bottom: 0;
            left: 0;
            transform: translateY(100%);
            opacity: 0.5;
            width: 100%;
            text-align: center;
        }
    }
}
