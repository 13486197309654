@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.Footer {
    width: 100%;
    //height: 500px;
    background: colors.$footer-background;
    align-items: flex-start;
    margin-top: 80px;

    //padding: 20px 0;

    .content {
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px 100px;
        //grid-row-gap: 120px;
        flex-wrap: wrap;

        //@include tablet-portrait-up {
        //    flex-direction: row;
        //}

        padding: 80px 75px 100px 75px;

        .column {
            //padding: 0 20px;
            display: flex;
            flex-direction: column;

            font-weight: 600;
            font-size: 20px;
            color: colors.$footer-col-text;

            .column-row {
                display: flex;
                flex-direction: column;
            }

            a {
                color: colors.$footer-col-text;
                text-decoration: none;

                &:hover {
                    color: colors.$accent;
                }
            }

            //&:last-child, &:first-child {
            //    padding: 0;
            //}

            .title {
                color: colors.$footer-col-title;
            }
        }
    }
}
