@use "src/scss/colors" as colors;

.underline-input, .form-control.underline-input {
    border: none;
    border-bottom: 1px solid colors.$border-bottom;
    border-radius: 0;
}

.text-orange {
    color: colors.$sidebar-title-color;
}

.color-inherit {
    color: inherit;
}

.fw-600 {
    font-weight: 600;
}

.material-symbols-outlined.inline-icon {
    font-size: 22px;
    display: inline-flex;
    vertical-align: top;
}

.color-block {
    width: 20px;
    height: 20px;
    display: block;
}

// link obfuscation to keep reversed links aligned left
a[href="obfuscated"] {
    text-align: end;
}

// Hide the Google reCAPTCHA badge
.grecaptcha-badge {
    visibility: hidden;
}
