@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.WeeklyChart {
    flex-direction: column;

    .chart {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;

        td, th {
            border: 1px solid #ddd;
            padding: 8px;
        }

        tr:nth-child(even){
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #ddd;
        }

        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #1a394c;
            color: white;
        }
    }
}
