@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.TopSongs {
    ol {
        padding: 0;

        .ArtworkDisplay {
            .text-container {
                gap: 8px;
            }
        }

        .ArtworkDisplay:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}
