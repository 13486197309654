$use-debug-colors: false;

$background: #E5E5E5;
$text-color-primary: #000;
$text-color-secondary: #686868;
$accent: #ED7D31;
$primary: #222A35;
$button-background: #222A35;
$button-text: #FFFFFF;
$border-bottom: #222A35;

$text-muted: #222A3580;
$date-selector-muted: $text-muted;

$home-title: $accent;

$sidebar-text-color: #222A35;
$sidebar-title-color: $accent;

$navbar-text: #FFFFFF;
$navbar-active: $accent;
$navbar-hover: $accent;
$navbar-background: #222A35;
$navbar-dropdown-background: lighten($navbar-background, 10%);

$about-title: $accent;

$schedule-title: $accent;
$schedule-separator: #222A35;

$shows-title: $text-color-primary;
$shows-show-title: $accent;

$songs-playlist-title: $accent;
$songs-text-color: #222A35;

$donate-button: $accent;
$donate-button-text: #222A35;

$footer-background: #222A35;
$footer-col-title: $accent;
$footer-col-text: #FFFFFF;

$streaming-background: #222A35;
$streaming-text: #FFFFFF;
