@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;
//@import "node_modules/bootstrap/scss/mixins";

.RequestBox {
    .request-form {
        display: flex;
        flex-direction: column;

        //label {
        //    font-weight: 500;
        //    width: 100%;
        //
        //    // TODO: Look into this, !optional shouldn't be needed
        //    @extend .mb-2 !optional;
        //
        //    display: flex;
        //
        //    span {
        //        font-weight: 500;
        //        font-size: 16px;
        //    }

            //input {
            //    flex: 1;
            //    margin-left: 16px;
            //    border: none;
            //    border-bottom: 1px solid colors.$border-bottom;
            //    width: 100%;
            //
            //
            //    //font-size: 20px;
            //    line-height: 22px;
            //    height: 22px;
            //
            //    &:focus {
            //        outline: none;
            //    }
            //}
        //}

        .Button {
            align-self: center;
        }
    }
}
