@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.Shows {
    display: flex;
    flex-direction: column;

    .main-title {
        color: colors.$shows-title;
        font-weight: 700;
        font-size: 48px;
        margin: 40px 0 190px 0;
    }

    h3 {
        color: colors.$schedule-title;
        font-weight: 700;
        font-size: 36px;
        margin: 0 0 24px 0;

        &.margin-top {
            margin-top: 50px;
        }
    }

    .items {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-gap: 50px 20px;
        margin: 1em 0 3em;
        justify-items: center;

        .show {
            display: flex;
            flex-direction: column;

            span {
                font-size: 36px;
                text-align: center;
                font-weight: 500;
            }

            .image {
                width: 240px;
                height: 240px;
            }

            .name {
                margin-top: 12px;
                font-weight: 700;
                color: colors.$shows-show-title;
                font-size: 24px;
            }

            .dj {
                font-size: 20px
            }

            .time {
                font-size: 20px;
            }
        }
    }
}
