@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.Files {
    flex-direction: column;

    .modify, .delete, .upload {
        border-radius: 4px;
        width: fit-content;
        padding: 8px;
        border: none;
        cursor: pointer;
        color: #FFF;
        text-decoration: none;
    }

    .upload {
        background: #8080FF;
    }

    .files {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .file {
            padding: 16px;
            margin: 10px;
            border: #555 2px solid;
            border-radius: 8px;
            display: flex;
            flex-direction: column;

            .restricted {
                color: #E00000;
            }

            .buttons {
                display: flex;
                flex-direction: row;
                padding-top: 8px;
                margin-top: auto;

                .modify {
                    background: #00E000;
                }

                .delete {
                    background: #555555;
                    margin-left: 10px;
                }
            }
        }
    }
}
