@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.Show {
    flex-direction: column;

    .show-edit {
        color: #000;
        opacity: 0.5;
        text-decoration: none;
        font-size: 50%;
        cursor: pointer;
    }

    .blog-posts {
        .post {
            display: flex;
            flex-direction: column;

            border: #000 1px solid;
            border-radius: 15px;

            padding: 16px;
            margin: 16px 0;

            .edit {
                opacity: 0;
                transition: opacity 350ms ease-out, z-index 0s linear 400ms;
                margin-left: auto;
                color: lighten(colors.$text-color-primary, 50%);
                cursor: pointer;

                &.persistent {
                    opacity: 1;
                }
            }

            &:hover {
                .edit {
                    opacity: 1;
                    transition: opacity 200ms ease-in;
                }
            }

            .title-line {
                display: flex;

                .draft {
                    color: #E00000;
                    margin-left: 16px;
                }

                .title {
                    display: inline-block;
                    margin: 0;
                }
            }

            .creation-info {
                display: flex;
                flex-direction: column;
                color: lighten(colors.$text-color-primary, 50%);
            }

            .editor-textarea {
                display: none;
            }

            .EasyMDEContainer {
                margin-top: 16px;
            }

            .update {
                background: #00E000;
                border: none;
                display: block;
                padding: 15px;
                width: fit-content;
                cursor: pointer;
                border-radius: 15px;
                color: #FFF;
            }
        }
    }
}
