@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

$image-container-width: 310px;

.StaffList {
    flex-direction: column;

    .items {
        .staff-display {
            width: $image-container-width;

            .menu-wrapper {
                user-select: none;
                right: 0;

                .more-dots {
                    cursor: pointer;
                }

                .popup {
                    z-index: $zindex-dropdown;
                }
            }

            .editable {
                .material-symbols-outlined {
                    cursor: default;
                }

                .eboard-status {
                    cursor: pointer;
                    color: var(--bs-success);
                    
                    &:not(.eboard-status-show) {
                        display: none;
                    }
                }

                &:hover {
                    .edit {
                        display: block;
                    }

                    .eboard-status {
                        display: block;
                        
                        &:not(.eboard-status-show) {
                            color: var(--bs-gray);

                            &:hover {
                                color: var(--bs-success);
                            }
                        }
                        
                        &.eboard-status-show:hover {
                            color: var(--bs-red);
                        }
                    }
                }

                .edit {
                    display: none;
                }

                .done, .edit, .cancel {
                    cursor: pointer;
                }
            }
        }
    }
}

.AddStaff {
    .image-row {
        width: $image-container-width;
    }
}
