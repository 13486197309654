@use "~react-big-calendar/lib/sass/styles" as *;

.ScheduleEdit {
    .calendar-wrapper {
        height: calc(100vh - 200px);
    }

    .material-symbols-outlined {
        font-variation-settings:
                'FILL' 0,
                'wght' 700,
                'GRAD' 0,
                'opsz' 40;

        &.add {
            vertical-align: middle;
        }
    }

    .weekday:first-of-type {
        border-top-left-radius: var(--bs-btn-border-radius) !important;
        border-bottom-left-radius: var(--bs-btn-border-radius) !important;
    }

    .event-wrapper {
        .excluded {
            text-decoration: line-through;
        }
    }

    //.weekday:focus, .weekday:active {
    //    color: var(--bs-btn-color);
    //    background-color: var(--bs-btn-bg);
    //    border-color: var(--bs-btn-border-color);
    //}
}
