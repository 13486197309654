.UserSelector {
    .focused {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }
    
    .badge {
        font-size: 16px;
    }
    
    .delete {
        font-size: 16px;
        cursor: pointer;
        
        &:hover {
            opacity: 0.8;
        }
    }
    
    .autocomplete-wrapper {
        &::after {
            pointer-events: none;
            content: attr(data-autofill);
            color: #4f5050;
            white-space: nowrap;
            width: 100px;
            position: absolute;
            padding: 1px 2px;
            margin-top: 3px;
        }
    }
    
    .remaining-input {
        margin-top: 3px;
        border: none;
        
        &:focus {
            outline: none;
        }
    }
}