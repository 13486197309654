@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.ArtworkDisplay {
    display: flex;
    flex-direction: row;
    color: colors.$sidebar-text-color;

    .image-link {
        text-decoration: none;
    }

    .image {
        width: 60px;
        height: 60px;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        justify-content: center;

        // This is to prevent text overflowing, at least in the Top 5 Weekly songs
        width: calc(100% - 60px);

        color: currentColor;
        white-space: nowrap;

        .primary-text {
            font-weight: 600;
            font-size: 16px;

            text-overflow: ellipsis;
            overflow: hidden;
        }

        .secondary-text {
            font-weight: 500;
            font-size: 16px;
            //line-height: 33px;
            margin-top: -3px;
        }
    }
}
