.NotFound {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .centering {
        text-align: center;
        width: 100%;
    }
}
