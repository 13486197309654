@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

.Users {
    .items {
        .account-disabled, .topt-unconfirmed {
            color: $danger;
        }

        .account-enabled, .register-complete, .topt-confirmed {
            color: $success;
        }

        .register-email-sent {
            color: $info;
        }
        .register-email-delayed {
            color: $warning
        }
    }
}
