@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.Support {
    flex-direction: column;

    .submit {
        background: #00E000;
        border: none;
        display: block;
        padding: 15px;
        width: fit-content;
        cursor: pointer;
        border-radius: 15px;
        color: #FFF;
    }
}
