@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

$itemWidth: 200px;
$itemWidthSmall: 150px;

.Home {

    .ListingComponent {
        .show-list {
            &.items {
                grid-template-columns: repeat(auto-fit, $itemWidth);
            }

            &.item {
                width: $itemWidth;
            }

            @include media-breakpoint-down(sm) {
                &.items {
                    grid-template-columns: repeat(auto-fit, $itemWidthSmall);
                }

                &.item {
                    width: $itemWidthSmall;
                }
            }
        }
    }

}
