@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

//$upper-background: #FFF;
//$player-background: #1a394c;
//$song-color: #FFF;
//$artist-color: darken($song-color, 30%);
//$streams-button-color: lighten($player-background, 20%);
//$streams-color: #FFF;

//$name-color: #000;
//$nav-background: #FFF;
//$nav-dropdown-background: lighten($nav-background, 10%);
//$nav-item-hover-background: darken($nav-background, 10%);

$mobile-nav-dropdown-color: lighten(colors.$text-color-primary, 50%);
$mobile-nav-close-color: lighten(colors.$text-color-primary, 30%);
$mobile-nav-close-active-color: lighten($mobile-nav-close-color, 30%);

$bottom-border: darken(#FFF, 5%);

.Header {
    width: 100%;
    display: block;
    border-bottom: 1px solid $bottom-border;

    &:not(.icons-loaded) .material-symbols-outlined {
        display: none;
    }

    @include media-breakpoint-only(md) {
        margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
    }
}

.hamburger {
    float: right;
    color: black;
    font-size: 35px;
    padding: 20px;
    user-select: none;
}

.nav {
    background: colors.$navbar-background;
    //height: 125px;

    .nav-content {
        display: flex;

        .close-button {
            display: none;

            position: absolute;
            color: $mobile-nav-close-color;
            cursor: pointer;

            text-align: center;
            font-size: 40px;
            line-height: 20px;

            width: 29px;
            height: 29px;

            top: 23px;
            right: 23px;
        }

        svg {
            width: 70px;
            height: 70px;
            margin: 19px 19px 19px 50px; // TODO
            //margin: 19px 19px 5px 50px; // TODO
        }

        & > ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            height: 108px;

            &.left-nav {
                overflow: hidden;
                flex-wrap: wrap;
            }

            &:not(.dropdown-content) {
                //width: 100%;
            }

            //background-color: colors.$navbar-background;

            li {
                float: left;
                cursor: pointer;

                padding: 40px 15px 40px;

                -webkit-tap-highlight-color: transparent;

                //&.right {
                //    float: right;
                //
                //    &:last-child {
                //        margin-right: 75px;
                //    }
                //}

                a, .logout, .account, .more {
                    display: block;
                    color: colors.$navbar-text;
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                    line-height: 20px;
                    text-decoration: none;
                    padding-bottom: 3px;

                    white-space: nowrap;

                    &:not(.more):hover {
                        color: colors.$navbar-hover;
                    }

                    &.active {
                        border-bottom: 3px solid colors.$navbar-active;
                    }
                }

                &.accentColor > a {
                    color: colors.$navbar-hover;
                }
            }
        }

        .dropdown-content {
            li {
                float: unset;
            }
        }
    }

    @include media-breakpoint-down(md) {
        height: 100%;
        display: block;
        position: fixed;
        top: 0;
        z-index: -1;

        opacity: 0;
        transition: opacity 350ms ease-out, z-index 0s linear 400ms;

        &.visible {
            opacity: 1;
            z-index: 10;

            transition: opacity 200ms ease-in;
        }

        .nav-content {
            flex-direction: column;

            .close-button {
                display: block;
                -webkit-tap-highlight-color: transparent;

                &:active {
                    color: $mobile-nav-close-active-color;
                }
            }

            & > ul {
                height: unset;

                li {
                    float: unset;
                    text-align: center;

                    padding: 30px 15px 30px;

                    &.right {
                        float: unset;
                    }

                    a {
                        display: initial;
                    }

                    a, span {
                        &:hover {
                            background: initial;
                        }

                        &:active {
                            color: colors.$accent;
                        }
                    }
                }
            }

            .dropdown-content {
                width: 100%;
                height: 100%;
                max-height: 0;

                display: block;
                box-shadow: none;
                position: relative;

                transition: max-height 250ms ease-out;

                a {
                    color: $mobile-nav-dropdown-color;
                }
            }

            .expanded .dropdown-content {
                max-height: 200vh;
                transition: max-height 500ms ease-in;
            }
        }
    }

    .dropdown {
        &:hover {
            .dropdown-content {
                display: block;
            }

            .inline-icon {
                transform: rotateZ(180deg);
            }
        }

        &:not(.visible) {
            opacity: 0;
            pointer-events: none;

            .inline-icon {
                display: none;
            }
        }

        .dropdown-content {
            display: none;
            position: absolute;
            list-style-type: none;
            background-color: colors.$navbar-dropdown-background;
            min-width: 160px;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            top: 100%;
            left: 0;
            padding-left: 0;
            z-index: 1;
        }
    }
}
