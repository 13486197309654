@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.StreamingBar {
    position: fixed;
    bottom: 0;

    display: flex;
    flex-direction: row;
    align-self: center;

    background-color: colors.$streaming-background;
    border-top: tint-color(colors.$streaming-background, 5%) solid 1px;

    height: 100px;
    width: 100%;

    .content {
        .info {
            display: flex;
            align-items: center;

            .ArtworkDisplay {
                color: colors.$streaming-text;
            }
        }

        .control {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                color: white;
                cursor: pointer;
                display: block;
                width: 40px;
                height: 40px;
                font-size: 40px;
                line-height: 40px;
            }
        }

        .stream {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .player {
                display: none;
            }

            .dropdown-selector {
                .dropdown {
                    position: absolute;
                    bottom: 100px;
                    right: 0;
                    z-index: 0;

                    //display: flex; // todo none
                    display: none; // todo none
                    flex-direction: column;

                    background: lighten(colors.$streaming-background, 10%);

                    .fm {
                        padding-top: 8px;
                    }

                    .underground {
                        padding-bottom: 8px;
                    }

                    span {
                        padding: 8px 0 8px 24px;
                        display: block;
                    }

                    .quality-container {
                        display: flex;
                        flex-direction: row;
                        gap: 12px;
                        margin: 8px 16px;

                        .Button {
                            color: #FFFFFF;
                        }
                    }

                    &::after {
                        display: block;
                        position: absolute;
                        bottom: -100px;
                        content: '';
                        height: 100px;
                        width: 100%;
                        z-index: 0;
                        background: transparent;
                    }
                }

                .expand_more {
                    display: none;
                }

                .expand_less {
                    display: inline;
                }

                &:hover {
                    .expand_more {
                        display: inline;
                    }

                    .expand_less {
                        display: none;
                    }

                    .dropdown {
                        display: flex;
                    }
                }

                span {
                    color: colors.$streaming-text;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }
}
