@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;
@use "src/scss/reactivity";
@use "src/scss/witr_style";

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;500;600;700;800');
//@import url('https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

body {
  margin: 0;
}

//code {
//  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//    monospace;
//}

html, body, #root, .flex-wrapper {
  font-size: 100%;
  height: 100%;
}

// Used for the footer
.flex-wrapper {
  // TODO: Why was this uncommented??
  //display: flex;
  //flex-direction: column;

  & > div:nth-child(2) {
    margin-left: 10px;
    margin-right: 10px;

    @include media-breakpoint-up(xl) {
      margin-left: 50px;
      margin-right: 50px;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: 75px;
      margin-right: 75px;
    }
  }

  // Ensuring the body and sidebar can go from row to column justification
  // Also, to make the body and sidebar centered when necessary
  & > div:nth-child(2) {
    //align-self: center;
    //justify-self: center;

    display: block;
    flex: 1 0 auto;
  }
}

.Footer {
  flex-shrink: 0;
}
