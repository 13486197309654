@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

// TODO: Legacy; Replace with bootstrap stuff
$blue-space: 27px * calc(2/3);
$green-space: 18px * calc(2/3);
$red-space: 48px * calc(2/3);

.SpotlightedPost {
    .spotlight-image {
        -o-object-fit: contain;
        object-fit: contain;
        object-position: top;
        float: left;
    }

    .spotlight-content {
        .source {
            font-weight: 600;
            font-size: 16px;
            color: colors.$text-color-secondary;
        }

        .title {
            font-family: "Bai Jamjuree", sans-serif;
            font-weight: 600;
            font-size: 24px;
        }

        .author {
            font-weight: 500;
            font-size: 16px;
            color: colors.$text-color-secondary;
        }

        .summary {
            margin: 0;
            padding: 0;

            font-weight: 400;
            font-size: 16px;

            text-align: justify;
        }
    }
}
