@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

.DropdownDate {
    display: inline-block;
    width: 200px;

    .handle {
        &.empty {
            color: $input-placeholder-color;
            user-select: none;
        }
    }

    .DateTimeChooser {
        display: block;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        background-color: #f9f9f9;
        z-index: 10;
    }
}