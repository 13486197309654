.ReCaptchaNotice {
    font-size: 14px;
    user-select: none;
    pointer-events: none;
    transition: opacity 200ms ease-in;
    opacity: 0;

    &.visible {
        pointer-events: unset;
        transition: opacity 200ms ease-out;
        opacity: 1;
    }
}
