@use "src/scss/colors" as colors;
@use "src/scss/mixins" as *;

.SystemPost {
    .post {
        font-variation-settings:
                'FILL' 1,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;
        
        .post-image-display {
            height: 100%; 
            max-height: 300px;
            
            & > img {
                max-height: 300px;
            }
        }
        
        .edit {
            right: 0;
            cursor: pointer;
        }

        .posted-label {
            height: 37px;
            line-height: 37px;
            user-select: none;
        }

        .EasyMDEContainer {
            margin-top: 1rem;
        }
        
        .upload-image {
            max-height: 300px;
        }
        
        .image-preview {
            height: 300px;
        }
    }
}
