@use "src/scss/colors" as colors;
//@use "src/scss/mixins" as *;

.DateTimeChooser {
    background: #FFFFFF;
    width: fit-content;

    .time-selector {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 0 16px 16px 16px;
        background: #FFFFFF;

        .colon {
            margin: 0 8px;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            margin-left: 8px;
            gap: 8px;
        }
    }
}
